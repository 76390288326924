<template>
    <div class="container-fluid pl-0 pr-0">
        <div class="d-sm-flex justify-content-sm-center d-md-flex flex-md-row-reverse pr-0">
            <form class="form-login-new validate-form bg-white">
                <div class="text-left"><img class="my-4" src="/assets/smartcat/logo.png" width="150"></div>
                <h2 class="mb-4 text-left">
                    Hi, <img src="/assets/smartcat/hello.png" width="25"><br>
                    Welcome to SmartCat    
                </h2> 
                <div class="input-group input-group-lg style-input pl-0 pr-0">
                    <div class="input-group-prepend">
                        <div class="input-group-text"><i class="fa fa-user" aria-hidden="true"></i></div>
                    </div>
                    <input id="name" type="text" class="form-control" :placeholder="tt('username')" autofocus="" autocomplete="off" v-model="username" v-on:keyup.enter="login">
                </div>
                <div class="input-group input-group-lg pt-3 style-input pl-0 pr-0">
                    <div class="input-group-prepend">
                        <div class="input-group-text"><i style="font-size: 15px;" class="fas fa-key" aria-hidden="true"></i></div>
                    </div>
                    <input id="password" ref="password" :type="eyeClassPassword ? 'text' : 'password'" class="form-control" name="password" :placeholder="tt('password')" v-model="password" v-on:keyup.enter="login" autocomplete="off">
                    <div class="input-group-append">
                        <button class="input-group-text" :class="{'fa fa-eye-slash' : eyeClassPassword,'fa fa-eye text-primary': !eyeClassPassword}" style="font-size: 15px;" type="button" @click="handlePassword"></button>
                    </div>
                </div>
                    <p class="pt-3 text-center">
                    <button type="button" class="btn btn-md btn-block btn-login" style="background: linear-gradient(to left, #2e41db, #5626c7); color: #fff;outline-color: black;" v-on:click="login()" :disabled="btnLogin.onLoading">
                        <span v-if="btnLogin.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                        <span v-else>{{ tt('login') }}</span>
                    </button>
                </p>   
                <router-link to="/forgot-password" class="text-center" tag="p">
                    <a href="" style="font-size: 12px"><b>{{ tt('forgot_password') }}</b></a>
                </router-link>
            </form>

            <div class="imageLogin d-none d-xl-block d-lg-block d-md-block" style="background-image: url('/assets/smartcat/new-login.jpg');"></div>
        </div>
    </div>
</template>
<script>
  import {mapActions} from 'vuex'
  import router from '@/routes/router'
  import Api from '@/helpers/api';
  import authService from '@/services/auth.service';

  
  export default {
    data() {
      return {        
        btnLogin: {
          onLoading: false
        },
        username: '',
        password: ''   ,
        eyeClassPassword:false,               
      };
    }, 
    methods: {
      ...mapActions({
        authToken: 'auth/setToken',
        authAttemp: 'auth/attemp'  
      }),
      login() {                
        let context = this;        
        if (this.username && this.password) {
          this.btnLogin.onLoading = true;
          Api(context, authService.login(this.username, this.password))
            .onSuccess(function(response) {            
              // console.log(response)
              context.authToken(response.data.token)            
              Api(context, authService.userProfile()).onSuccess(function(response) {          
                // console.log(response.data.data.user)        
                context.$notify({
                  message: context.tt('success_login', {name: response.data.data.user.user.name}),                                 
                  type: 'success',
                  verticalAlign: 'bottom', 
                  horizontalAlign: 'left'
                });
                
                context.authAttemp(response.data.data.user);                                                         
                router.push('/dashboard/my-task');
              }).call();          
            }).onError(function(error) {
              var response = error.response;
              context.alertDialog('error', response.data.error);
              context.btnLogin.onLoading = false;
            })
            .call()
        } else {
          this.alertDialog('error', this.tt('error_username_password_required'));
        }
      },
      handlePassword(){
          this.eyeClassPassword = !this.eyeClassPassword
      }
    },
  }
</script>

<style>
    .imageLogin {
        width: calc(100% - 560px);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
        z-index: 1;
    }

    .imageLogin::before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.1);
    }



    /*==================================================================
    [ Form ]*/

    .form-login-new {
        width: 560px;
        min-height: 100vh;
        background-color: #f7f7f7;
        padding: 173px 55px 55px 55px;
    }

    .form-login-new-title {
        width: 100%;
        display: block;
        font-family: Poppins-Regular;
        font-size: 30px;
        color: #333333;
        line-height: 1.2;
        text-align: center;
    }
</style>